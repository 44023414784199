<template>
  <div class="con-wrap-bg">
    <b-container fluid>
      <h2 class="page-title">个人中心</h2>
      <b-row class="member-info">
        <b-col class="b-col" sm="12" md="6" lg="6" xl="6">
          <div class="left-con">
            <div class="member-avatar">
              <img :src="memberInfo.head_photo ? memberInfo.head_photo : require('@/../public/icon/user-default-avatar.jpg')" :alt="memberInfo.name">
            </div>
            <div class="member-info-right">
              <div class="member-name">{{memberInfo.name}}&nbsp;欢迎来到名欧国际！</div>
              <div class="member-count-down">
                会员有效期：
                <span v-if="expired">您的会员已过期</span>
                <count-down v-else :remainTime="outTime" @countDowmEnd="monitorCountDowmEnd"></count-down>
              </div>
              <div class="member-order-info">
                <span @click="toPendingOrder">待付款订单({{memberInfo.pendPay_counts}})</span>
                <span @click="toOrderToBeReceived">待收货订单({{memberInfo.collectGoods_counts}})</span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col class="b-col" sm="12" md="6" lg="6" xl="6">
          <div class="right-con">
            <!-- <div class="member-integral">
              <p>您的积分</p>
              <div>
                <i class="el-icon-coin"></i>
                <span>{{memberInfo.integral}}</span>
              </div>
            </div>
            <div class="member-sign-in">
              <p>签到赚积分&gt;</p>
              <el-button :type="isSign ? 'info' : 'warning'" :disabled="isSign ? true : false" size="mini" @click="signIn">{{isSign ? "已签到" : "签到"}}</el-button>
            </div> -->
          </div>
        </b-col>
      </b-row>
      <h2 class="page-title">热销商品</h2>
      <b-row class="product-items">
        <b-col class="item-wrap" sm="6" md="4" lg="4" xl="3" v-for="(item, index) in hotGoods" :key="index">
          <div class="item">
            <div class="pic" @click="toProductDetail(item)">
              <img :src="item.file_path" :alt="item.goods_name">
            </div>
            <div class="price">￥{{item.sale_price}}</div>
            <div class="name" @click="toProductDetail(item)">{{item.goods_name}}</div>
            <div class="sold-num">
              已售
              <span>{{item.sale_count}}</span>
              件
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import dump from "@/util/dump";
  import countDown from "@/components/countDown";
  export default {
    components: {
      countDown
    },
    data (){
      return {
        token: "",
        memberInfo: [],
        hotGoods: [],
        isSign: 0,
        expired: false,
        outTime: 0,
        roleId: ''
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.outTime = (new Date(localStorage.getItem('outTime')) - new Date())/1000;
        console.log(this.outTime)
        this.token = localStorage.getItem("token");
        this.roleId = localStorage.getItem("role_id");
        this.expired = this.roleId == '3' ? true : false;
        this.getUserInfo();
        this.getHotSaleGoods();
      },
      // 获取个人信息
      getUserInfo(){
        this.$api.getUserInfo({
          token: this.token
        }).then(res => {
          if(res.status === 100){
            this.memberInfo = res.data;
            this.isSign = res.data.is_sign;
          }
        }).catch(err => console.error(err));
      },
      // 获取热销商品
      getHotSaleGoods(){
        this.$api.hotSaleGoods({
          token: this.token
        }).then(res => {
          if(res.status === 100){
            this.hotGoods = res.data;
          }
        }).catch(err => console.error(err));
      },
      // 跳转到待付款订单
      toPendingOrder(){
        const params = {
          type: 1,
          link: "/usercenter/orderManagement",
          params: {
            status: 1,
            activeStatusIndex: 1
          }
        }
        dump.link(params);
      },
      // 跳转到待收货订单 
      toOrderToBeReceived(){
        const params = {
          type: 1,
          link: "/usercenter/orderManagement",
          params: {
            status: 3,
            activeStatusIndex: 3
          }
        }
        dump.link(params);
      },
      // 签到
      signIn(){
        if(this.isSign) return false;
        this.$api.sign({
          token: this.token
        }).then(res => {
          if(res.status === 100){
            this.getUserInfo();
            this.$message({
              message: '签到成功',
              type: 'success'
            });
          }
        })
      },
      // 监听倒计时是否已结束
      monitorCountDowmEnd(val){
        this.expired = val;
      },
      // 跳转到商品详情页
      toProductDetail(obj){
        const params = {
          type: 3,
          link: "/product/details",
          params: {
            good_id: obj.goods_id
          }
        }
        dump.link(params);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/personalCenter/personalCenter";
</style>
